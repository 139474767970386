import facebook from "@theme/icons/social/facebook.inline.svg";
import google from "@theme/icons/social/google.inline.svg";
import instagram from "@theme/icons/social/instagram.inline.svg";
// import telegram from "@theme/icons/social/telegram.inline.svg";
import twitter from "@theme/icons/social/twitter.inline.svg";
import whatsapp from "@theme/icons/social/whatsapp.inline.svg";
// import youtube from "@theme/icons/social/youtube.inline.svg";

export const iconsSocial: Record<string, string> = {
    facebook,
    google,
    instagram,
    // telegram,
    twitter,
    whatsapp,
    // youtube,
};
